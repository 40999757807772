import { Card, Divider, Image, Button } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { useMaterialFetchRequest } from "../../Hooks/materials/useMaterials.query";

const ViewMaterials = () => {
  const { id } = useParams();

  const { data } = useMaterialFetchRequest(id);
  const material = data?.data;

  return (
    <div className="layout-content">
      <Card title={material?.title} bordered={false}>
        {material?.description}

        <Divider />

        <br />
        <Button
          href={material?.file}
          color="transparent"
          target="_blank"
          download
        >
          Download
        </Button>
        <br />
        <br />
        <Image width={350} src={material?.file} />
      </Card>
    </div>
  );
};

export default ViewMaterials;
