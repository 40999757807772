import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getMaterials = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/materials`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};
const getMaterial = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/v1/materials/view?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useMaterialsFetchRequest() {
  return useQuery(["materials"], () => getMaterials(), {
    onSuccess: (data) => {
      return data;
    },
  });
}

export function useMaterialFetchRequest(id) {
  return useQuery(["material", id], () => getMaterial(id), {
    onSuccess: (data) => {
      return data;
    },
  });
}
