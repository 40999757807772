import { Navigate, useNavigate, useRoutes } from "react-router-dom";
import { useAccessFetchRequest } from "../Hooks/access/useAccess.query";
import PageLoading from "../components/PageLoading";
import Main from "../components/layout/Main";
import Advice from "../pages/Advice";
import CreateAdvice from "../pages/Advice/create";
import Documentation from "../pages/Advice/documentation";
import AdviceBank from "../pages/AdviceBank";
import DataAdviceBank from "../pages/AdviceBank/data";
import Copy from "../pages/Copy";
import CreateCopy from "../pages/Copy/create";
import Home from "../pages/Home";
import Materials from "../pages/Materials";
import ViewMaterials from "../pages/Materials/view";
import Profile from "../pages/Profile";
import Update from "../pages/Profile/update";
import SignIn from "../pages/SignIn";
import Simulator from "../pages/Simulator";
import User from "../pages/User";
import Users from "../pages/Users";
import CreateUser from "../pages/Users/create";
import { isAuthenticated } from "../services/auth";

export default function MainRouter() {
  const navigate = useNavigate();

  const { data, isLoading: loading, status } = useAccessFetchRequest();
  const access = data?.data?.access;

  // if (data?.status === 500 || data?.status === 400) {
  //   localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
  //   navigate("/login");
  // }
  return useRoutes([
    {
      path: "/",
      errorElement: "ERROR...",
      element: loading ? (
        <PageLoading />
      ) : isAuthenticated() ? (
        <Main />
      ) : (
        <Navigate to="/login" />
      ),
      children: [
        { element: <Navigate to="/painel" /> },
        { path: "/painel", element: <Home /> },
        access >= 50 && {
          path: "investidor",
          children: [
            { path: "", element: <Users /> },
            { path: "criar", element: access >= 50 ? <CreateUser /> : "401" },
            // { path: "editar/:id", element: access >= 99 ? <EditUser /> : "401" },
            { path: "perfil/:id", element: <User /> },
          ],
        },
        access >= 50 && {
          path: "simulator",
          children: [{ path: "", element: <Simulator /> }],
        },
        access >= 50 && {
          path: "perfil",
          children: [
            { path: "", element: <Profile /> },
            { path: "editar", element: <Update /> },
          ],
        },
        access >= 50 && {
          path: "assessoria",
          children: [
            { path: "", element: <Advice /> },
            { path: "criar", element: access >= 50 ? <CreateAdvice /> : "401" },
            {
              path: "documentacao/:id",
              element: access >= 50 ? <Documentation /> : "401",
            },
          ],
        },
        access >= 50 && {
          path: "material",
          children: [
            { path: "", element: <Materials /> },
            { path: ":id", element: <ViewMaterials /> },
            // { path: "criar", element: access >= 50 ? <CreateAdvice /> : "401" },
            {
              path: "documentacao/:id",
              element: access >= 50 ? <Documentation /> : "401",
            },
          ],
        },
        access >= 50 && {
          path: "copy",
          children: [
            { path: "", element: <Copy /> },
            { path: "criar", element: access >= 50 ? <CreateCopy /> : "401" },
          ],
        },
        access === 20 && {
          path: "assessoria",
          children: [
            { path: "", element: <AdviceBank /> },
            { path: "ver/:id", element: <DataAdviceBank /> },
          ],
        },
      ],
    },
    { path: "/login", element: <SignIn /> },
    { path: "*", element: "404" },
  ]);
}
