import React from "react";
import { Table } from "antd";
import { useMaterialsFetchRequest } from "../../Hooks/materials/useMaterials.query";
import { useNavigate } from "react-router-dom";

const Materials = () => {
  const navigate = useNavigate();
  const { data } = useMaterialsFetchRequest();

  return (
    <Table
      columns={[
        {
          title: "Titulo",
          dataIndex: "title",
          key: "title",
        },
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
        },
        {
          title: "Tipo",
          dataIndex: "type",
          key: "type",
        },
      ]}
      dataSource={data?.data}
      className="ant-border-space custom-row-hover"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(`${record?.id}`);
          },
        };
      }}
    />
  );
};

export default Materials;
